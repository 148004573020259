import { ActionTree } from 'vuex';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { GroupDefinition } from '@/domain/Group';
import { User } from '@/domain/User';
import { CourseDefinition } from '@/domain/Course';
import {
  getTeacherCurriculumStats,
  getCurriculumGradeStructure,
  getAssessmentStats,
} from '@/api/core/curricula.api';
import { findMenteeGroup, getMentees } from '@/api/core/mentees.api';
import { findCourses } from '@/api/core/course.api';
import { searchSchools } from '@/api/core/schools.api';
import { School } from '@/domain/School';
import { orderBy, shuffle } from 'lodash';
import axios from 'axios';
import { getSkillStrands, Standard } from '@/api/core/skills.api';
import { StandardNodeDefinition } from '@/domain/Skill';
import { getStandardNodes } from '@/api/core/skills.api';
import { getFolderMembers } from '@/api/core/folders.api';

export const actions: ActionTree<InsightsHubState, RootState> = {
  getCurriculumGradeFolders(context, xref) {
    // Reset Grade Folders
    context.commit('setGradeFolders', []);

    return getFolderMembers(xref).then((folders) => {
      context.commit('setGradeFolders', folders.data);
    });
  },
  // Gets mentee teachers for requesting user
  getMenteeTeachers(context) {
    // Get mentee groups
    return findMenteeGroup({ owner: 'me' }).then((group: GroupDefinition) => {
      // Get teachers
      return getMentees(group.groupXref, { attributes: true }).then(
        (menteeTeachers: User[]) => {
          context.commit('setMenteeTeachers', menteeTeachers);
        }
      );
    });
  },
  // Gets all courses for Mentor
  getMentorCourses(context) {
    return findCourses({ mentor: 'me', primaryTeachers: true }).then(
      (menteeCourses: CourseDefinition[]) => {
        context.commit('setMenteeCourses', menteeCourses);
      }
    );
  },
  // Gets all schools for menteeTeachers already in store
  getMenteeSchools(context) {
    const schoolIds = [];

    for (const menteeTeacher of context.state.menteeTeachers) {
      const menteeSchools = menteeTeacher.attributes?.ncesSchool ?? [];

      schoolIds.push(...menteeSchools);
    }

    if (schoolIds.length > 0) {
      return searchSchools(Array.from(new Set(schoolIds)).join(',')).then(
        (schools: School[]) => {
          context.commit('setMenteeSchools', schools);
        }
      );
    }
  },
  shuffleMenteeTeachers(context) {
    // Clear any table options prior so that rows can be shuffled
    context.commit('setCollapsedPaths', []);
    context.commit('setOptions', {
      ...context.state.options,
      sortBy: [],
      sortDesc: [],
    });

    // Shuffle order of Teachers
    const res = shuffle(context.state.menteeTeachers);

    // Update store with shuffled version
    context.commit('setMenteeTeachers', res);

    return Promise.resolve(res);
  },
  getDashboardData(context, curriculumXref) {
    const dashboardPromises = [];

    // Set loading state
    context.commit('setDashboardLoading', true);

    if (curriculumXref && context.state.selectedGradeXref) {
      // Stop whatever was previously not downloaded (or downloading)
      if (context.state.dashboardSource) {
        // Cancel prior requests with this cancel token
        context.state.dashboardSource.cancel();
      }

      // Clear prior dashboard data if any
      context.commit('setCurriculumGrade', null);
      context.commit('setTeacherCurriculumStats', []);

      // New Token
      context.commit('setDashboardSource', axios.CancelToken.source());

      const folderStructurePromise = getCurriculumGradeStructure(
        curriculumXref,
        context.state.selectedGradeXref,
        context.state.dashboardSource?.token
      ).then((res) => {
        context.commit('setCurriculumGrade', res);
      });

      dashboardPromises.push(folderStructurePromise);

      const statsPromise = getTeacherCurriculumStats(
        curriculumXref,
        context.state.selectedGradeXref,
        {
          mentor: 'me',
          primaryTeachers: true,
          timeSelector: context.state.timeSelector ?? undefined,
        },
        context.state.dashboardSource?.token
      ).then((res) => {
        context.commit('setTeacherCurriculumStats', res);
      });

      dashboardPromises.push(statsPromise);

      return Promise.all(dashboardPromises).then(() => {
        context.commit('setDashboardLoading', false);
      });
    }
  },
  getAchievementData(context) {
    const dashboardPromises = [];

    // Set loading state
    context.commit('setDashboardLoading', true);

    if (context.state.selectedCurriculum && context.state.selectedGradeXref) {
      // Stop whatever was previously not downloaded (or downloading)
      if (context.state.dashboardSource) {
        // Cancel prior requests with this cancel token
        context.state.dashboardSource.cancel();
      }

      // New Token
      context.commit('setDashboardSource', axios.CancelToken.source());

      const assessmentPromise = getAssessmentStats(
        context.state.selectedCurriculum.xref,
        context.state.selectedGradeXref,
        {
          mentor: 'me',
          primaryTeachers: true,
          timeSelector: context.state.timeSelector ?? undefined,
        },
        context.state.dashboardSource?.token
      ).then((res) => {
        return res;
      });

      dashboardPromises.push(assessmentPromise);
    }

    return Promise.all(dashboardPromises).then(() => {
      context.commit('setDashboardLoading', false);
    });
  },

  getAllSkillStrands(context) {
    getSkillStrands(Standard.COMMON_CORE).then((skillStrands) => {
      context.commit(
        'setSkillStrands',
        orderBy(skillStrands, (skillStrand) => skillStrand.code, 'asc')
      );
    });
  },

  // TODO: Figure out if we can use the skill store instead.
  requestStandardNodes(context): Promise<StandardNodeDefinition[]> {
    if (
      !context.state.hasDownloadedSkills &&
      !context.state.isDownloadingSkills
    ) {
      // Let the component decide when to load or reload skills
      // Update loading state here to notify other instances or components if
      // we do perform such operation rather than having the calling component do
      // this kind of update?
      context.commit('setIsDownloading', true);
      return getStandardNodes(Standard.COMMON_CORE).then(
        (nodes: StandardNodeDefinition[]) => {
          context.commit('setHasDownloaded', true);
          context.commit('setIsDownloading', false);
          context.commit('setStandardNodes', nodes);
          return Promise.resolve(nodes);
        }
      );
    }
    return Promise.resolve(context.state.standardNodes);
  },
};
