var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-8"},[(_setup.isAuthenticated && !_setup.unauthorized)?_c('div',[_c('div',{staticClass:"neutral lighten-4 pb-4",attrs:{"id":"dashboard-student-selection"}},[_c('v-row',_vm._l((4),function(i){return _c('v-col',{key:`student-${i}`,staticClass:"pb-0"},[_c('v-select',{attrs:{"items":_setup.tutees,"item-text":"displayName","item-value":"xref","label":`Student ${i}`,"clearable":"","filled":"","flat":"","data-cy":`tutee-dropdown-${i}`,"menu-props":{
              offsetY: true,
              offsetOverflow: true,
              contentClass: `tutee-dropdown-${i}-select-list`,
            },"loading":_setup.isDownloadingTutees},model:{value:(_setup.tuteeXrefs[i - 1]),callback:function ($$v) {_vm.$set(_setup.tuteeXrefs, i - 1, $$v)},expression:"tuteeXrefs[i - 1]"}})],1)}),1)],1),(!_setup.noTuteeSelected)?_c('v-row',{staticClass:"mx-9"},[_c(_setup.ViewByFilters,{on:{"viewByChanged":_setup.onViewByChanged}})],1):_vm._e(),(!_setup.noTuteeSelected)?_c('div',{staticClass:"neutral lighten-4 elevation-1 mt-6",attrs:{"id":"dashboard-content-overview"}},[_c(_setup.DateRangeDialog,{attrs:{"range":_setup.weekSelected},on:{"select":_setup.selectTimeFrame},model:{value:(_setup.showDateRangeSelectDialog),callback:function ($$v) {_setup.showDateRangeSelectDialog=$$v},expression:"showDateRangeSelectDialog"}}),_c(_setup.AssignmentSelectionDialog,{attrs:{"data":_setup.assignmentDefinitions},model:{value:(_setup.showAssignmentSelectionDialog),callback:function ($$v) {_setup.showAssignmentSelectionDialog=$$v},expression:"showAssignmentSelectionDialog"}}),_c(_setup.StandardSelectionDialog,{attrs:{"standardNodes":_setup.assignmentStandardNodes},model:{value:(_setup.showStandardSelectionDialog),callback:function ($$v) {_setup.showStandardSelectionDialog=$$v},expression:"showStandardSelectionDialog"}}),_c(_setup.SkillBarChartViewForReport,{staticClass:"elevation-1 px-8",attrs:{"customChartLabels":_setup.customChartLabels,"customClassAverageColor":_setup.tutorHelper.classAverageColor.value,"skillData":_setup.filteredSkillData,"isLoading":_setup.isDownloadingSkillData || !_setup.hasDownloadedSkillData,"noSkillDataFound":_setup.noSkillDataFound}}),(!_setup.noAssignmentDataFound)?_c(_setup.AssignmentDataTable,{attrs:{"customHeaderLabels":_setup.customTableLabels,"loading":_setup.isDownloadingAssignmentReports ||
          _setup.isDownloadingStudentAssignments ||
          !_setup.hasDownloadedAssignmentReports ||
          !_setup.hasDownloadedStudentAssignments,"filteredTuteeXrefs":_setup.filteredTuteeXrefs,"assignmentDefinitions":_setup.filteredAssignments,"assignmentReportData":_setup.assignmentReportData}}):_vm._e()],1):_vm._e(),(_setup.noTuteeSelected)?_c(_setup.NoStudentSelectedView,{attrs:{"students":_setup.tutees},model:{value:(_setup.tuteeXrefs[0]),callback:function ($$v) {_vm.$set(_setup.tuteeXrefs, 0, $$v)},expression:"tuteeXrefs[0]"}}):(_setup.noDataFoundWhatsoever)?_c(_setup.NotFoundView,{attrs:{"text-color":"neutral"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Didn't find any problems for this time frame ")]},proxy:true}],null,false,3386287624)}):_vm._e()],1):(_setup.unauthorized)?_c(_setup.UnauthorizedView):_c(_setup.UnauthenticatedView,{attrs:{"button-color":"primary","text-color":"neutral"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Login Required")]},proxy:true},{key:"button-text",fn:function(){return [_vm._v("Login")]},proxy:true}])},[_vm._v(" You'll need to login to access ASSISTments Tutor. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }