import { ActionType } from '@/domain/Action';
import { StudentLog } from '@/domain/ReportData/StudentData';

export function calculatePausedTime(slog: StudentLog): number {
  let time = 0;
  let savedTime = 0;
  slog.psActions?.forEach((action) => {
    if (action.actionType === ActionType.TIMER_PAUSED_ACTION) {
      savedTime -= action.timestamp;
    } else if (
      action.actionType === ActionType.TIMER_RESUMED_ACTION &&
      savedTime !== 0
    ) {
      time += savedTime + action.timestamp;
      savedTime = 0;
    } else if (action.actionType === ActionType.ASSIGNMENT_RESUMED_ACTION) {
      savedTime = 0;
    }
  });
  return time;
}
