import { MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { StandardNodeDefinition } from '@/domain/Skill';
import { SkillState } from './types';

export const mutations: MutationTree<SkillState> = {
  setHasDownloaded(state: SkillState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: SkillState, flag: boolean) {
    state.isDownloading = flag;
  },
  setStandardNodes(state: SkillState, newList: StandardNodeDefinition[]) {
    state.standardNodes = cloneDeep(newList);
  },
  setSelectedStandardNode(state: SkillState, xref: string) {
    state.selectedStandardNode = xref;
  },
};
