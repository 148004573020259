import { StandardNodeDefinition, SkillStrand } from '@/domain/Skill';
import { coreAxios } from '@/plugins/axios';

const END_POINT = `skills`;

export enum Standard {
  COMMON_CORE = 3,
  COMMON_CORE_PLUS = 1,
}

// Defaults to COMMON_CORE_PLUS.
const getStandardNodes = (
  standard?: Standard
): Promise<StandardNodeDefinition[]> => {
  return coreAxios
    .get(`${END_POINT}/nodes`, {
      params: {
        standardId: standard,
      },
    })
    .then((result) => {
      const data = result.data.map(transformStandardNode);
      data.sort((a: StandardNodeDefinition, b: StandardNodeDefinition) => {
        let comp = 0;
        if (a.nodeCode > b.nodeCode) {
          comp = 1;
        } else if (a.nodeCode < b.nodeCode) {
          comp = -1;
        }
        return comp;
      });
      return data;
    });
};

const getSkillStrands = (standard?: Standard): Promise<Array<SkillStrand>> => {
  return coreAxios
    .get(`${END_POINT}/strands`, {
      params: {
        standardId: standard,
      },
    })
    .then((res) => {
      return res.data;
    });
};

function transformStandardNode(
  node: StandardNodeDefinition
): StandardNodeDefinition {
  return {
    ...node,
    // Internal processing only. Will not included in DTO if not set manually.
    displayName: `${node.nodeCode} - ${node.nodeName}`,
  };
}

export { getStandardNodes, getSkillStrands };
