import { GetterTree } from 'vuex';
import { SearchState } from './types';
import { RootState } from '../types';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';

export const getters: GetterTree<SearchState, RootState> = {
  getSearchResults(
    state: SearchState,
    getters,
    rootState: RootState
  ): (ProblemDefinition | ProblemSetDefinition)[] {
    return state.searchResults.map(
      (ceri) =>
        rootState.content.problemMap[ceri] ??
        rootState.content.problemSetMap[ceri]
    );
  },
  getSkillBuilders(
    state: SearchState,
    getters,
    rootState: RootState
  ): ProblemSetDefinition[] {
    return state.skillBuilders.map((sb) => rootState.content.problemSetMap[sb]);
  },
  getNumProblems(state: SearchState): number {
    return state.problemCount + state.problemSetCount;
  },
};
