import { GroupType } from './Group';
import { LmsProviderType } from './LmsProviderType';

// FIXME: To be deprecated
export interface Class {
  id: string;
  name: string;
  lmsType: LmsProviderType | null;
}

export interface LmsClass {
  id: string;
  name: string;
  lmsType: LmsProviderType | null;
}

export interface ClassWrapper {
  importedClasses?: Class[];
  unimportedClasses?: LmsClass[];
}

export enum ImportFilter {
  ALL = 'ALL',
  IMPORTED = 'IMPORTED',
  UNIMPORTED = 'UNIMPORTED',
}
