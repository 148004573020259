import {
  AnswerPart,
  AnswerSet,
  AnswerType,
  ProblemDefinition,
  ProblemTypeSDK3,
} from '@/domain/Problem';
import { ProblemLog } from '@/domain/ReportData/StudentData';
import { isEmpty } from 'lodash';

export interface ProblemTypeItem {
  text: string;
  value: ProblemTypeSDK3;
}

const getPartLetter = (index: number): string => {
  let mainLetter = -1;
  let secondLetter = -1;

  //If we need to use the second letter
  if (index >= 26) {
    while (index >= 26) {
      secondLetter++;
      index -= 26;
    }
  }

  //we have another problem if we somehow use all the double letter parts.
  // Someone would have to be crazy to get all the way through ZZ though...

  mainLetter = index;

  let part = 'Part ';

  if (secondLetter >= 0) {
    part += String.fromCharCode(64 + secondLetter);
  }

  part += String.fromCharCode(64 + mainLetter);

  return part;
};

/**
 * Score an individual problem log based on problem type
 * Assign partial credit to open response questions
 * All other questions are 1 if correct 0 otherwise
 * @param prLog: ProblemLog - the log to score
 * @returns score 0-1
 */
const scoreProblemDefinitionForStandardsReport = (
  prLog: ProblemLog,
  problem: ProblemDefinition | undefined // if no problem provided, no partial credit
): number => {
  switch (problem?.problemTypeSDK3) {
    // Count partial credit for open response questions
    case ProblemTypeSDK3.OPEN_RESPONSE:
      return prLog.continuousScore;
    // All other problem types are 1 or 0 only
    default:
      return prLog.discreteScore || 0;
  }
};

const getProblemTypeItems = (
  problemTypes: ProblemTypeSDK3[]
): ProblemTypeItem[] => {
  return problemTypes.map((problemType) => {
    return {
      text: getProblemTypeDisplayName(problemType),
      value: problemType,
    };
  });
};

const getProblemTypeDisplayName = (problemType: ProblemTypeSDK3): string => {
  let name = '';
  switch (problemType) {
    case ProblemTypeSDK3.CHOOSE_N:
      name = 'Multiple Choice (select all)';
      break;
    case ProblemTypeSDK3.CHOOSE_ONE:
      name = 'Multiple Choice (select 1)';
      break;
    case ProblemTypeSDK3.CLOZE:
      name = 'Cloze';
      break;
    case ProblemTypeSDK3.DRAG_DROP:
      name = 'Drag and Drop';
      break;
    case ProblemTypeSDK3.FILL_IN:
      name = 'Fill-in-the-blank(s)';
      break;
    case ProblemTypeSDK3.IFRAME:
      name = 'Iframe';
      break;
    case ProblemTypeSDK3.OPEN_RESPONSE:
      name = 'Open Response (ungraded)';
      break;
    case ProblemTypeSDK3.SORT:
      name = 'Ordering';
      break;
    default:
      break;
  }
  return name;
};

const getProblemTypeDescription = (problemType: ProblemTypeSDK3): string => {
  let description = '';
  switch (problemType) {
    case ProblemTypeSDK3.CHOOSE_N:
      description = 'Select all that apply:';
      break;
    case ProblemTypeSDK3.CHOOSE_ONE:
      description = 'Select one:';
      break;
    case ProblemTypeSDK3.DRAG_DROP:
      description = 'Drag and drop into correct places:';
      break;
    case ProblemTypeSDK3.FILL_IN:
      description = 'Type your answer below:';
      break;
    // FIXME: Migration - Description fix (CLOZE)
    case ProblemTypeSDK3.CLOZE:
      description = 'CLOZE DESCRIPTION';
      break;
    // FIXME: Migration - Description fix (IFRAME)
    case ProblemTypeSDK3.IFRAME:
      description = 'IFRAME DESCRIPTION';
      break;
    // FIXME: Migration - Description fix (OPEN_RESPONSE)
    case ProblemTypeSDK3.OPEN_RESPONSE:
      description = 'OPEN_RESPONSE DESCRIPTION';
      break;
    // FIXME: Migration - Description fix (SORT)
    case ProblemTypeSDK3.SORT:
      description = 'SORT DESCRIPTION';
      break;
    default:
      break;
  }

  return description;
};

const getAnswerTypeItems = (
  answerTypes: AnswerType[]
): { text: string; value: AnswerType }[] => {
  return answerTypes.map((answerType) => {
    return {
      text: getAnswerTypeDisplayName(answerType),
      value: answerType,
    };
  });
};

const getAnswerTypeDisplayName = (answerType: AnswerType): string => {
  let name = '';
  switch (answerType) {
    case AnswerType.ALGEBRA:
      name = 'Algebraic Expression';
      break;
    case AnswerType.CHOOSE_N:
      name = 'Multiple Choice (select all)';
      break;
    case AnswerType.CHOOSE_ONE:
      name = 'Multiple Choice (select 1)';
      break;
    case AnswerType.DRAG_DROP:
      name = 'Drag and Drop';
      break;
    case AnswerType.DROP_DOWN:
      name = 'Dropdown';
      break;
    case AnswerType.EXACT_MATCH:
      name = 'Exact Match';
      break;
    case AnswerType.EXACT_FRACTION:
      name = 'Exact Fraction';
      break;
    case AnswerType.IFRAME:
      name = 'Iframe';
      break;
    case AnswerType.NUMERIC:
      name = 'Numeric';
      break;
    case AnswerType.NUMERIC_EXPRESSION:
      name = 'Numeric Expression';
      break;
    case AnswerType.OPEN_RESPONSE:
      name = 'Open Response (ungraded)';
      break;
    case AnswerType.PURE_EQUIVALENCE:
      name = 'Pure Equivalence';
      break;
    case AnswerType.SORT:
      name = 'Order / Sort';
      break;
    case AnswerType.SYMBOLIC_EQUIVALENCE:
      name = 'Symbolic Equivalence';
      break;
    default:
      break;
  }
  return name;
};

const getAnswerTypeDescription = (answerType: AnswerType): string => {
  let details = '';
  switch (answerType) {
    case AnswerType.PURE_EQUIVALENCE:
      details = 'Anything mathematically equivalent accepted as correct';
      break;
    case AnswerType.SYMBOLIC_EQUIVALENCE:
      details =
        'Like symbols (x vs *) and differing order of terms (3+5 or 5+3) accepted as correct';
      break;
    case AnswerType.EXACT_MATCH:
      details = 'The value on the left is the only accepted answer';
      break;
    default:
      break;
  }
  return details;
};

const getAnswerParts = (
  as: AnswerSet | null | undefined
): Record<number, AnswerPart> => {
  const ases: AnswerSet[] = [];
  if (as) {
    ases.push(as);
  }
  const aps: Record<number, AnswerPart> = {};
  while (ases.length) {
    const as = ases.shift();
    const members = as?.members ?? [];
    for (const member of members) {
      switch (member.memberType) {
        case 'ANSWER_PART':
          aps[member.htmlMarker] = member;
          break;
        case 'ANSWER_SET':
          ases.push(member);
          break;
      }
    }
  }
  return aps;
};

// FIXME: Compute different combinations for interchangability.
// FIXME：Handle nested Answer Sets?
const getCorrectAnswer = (
  aps: Record<string, AnswerPart>
): Record<number, string[]> | undefined => {
  const correctResponse: Record<number, string[]> = {};
  for (const marker in aps) {
    const ap = aps[marker];
    const avs = ap.answerValues ?? [];
    for (const av of avs) {
      if (av.isCorrect) {
        const marker = ap.htmlMarker;
        const cvs = correctResponse[marker] ?? [];
        const dropCorrectMap = ap.properties?.DROP_CORRECT ?? {};
        const count = dropCorrectMap[av.value] ?? 1;
        cvs.push(...Array(count).fill(av.value));
        correctResponse[marker] = cvs;
      }
    }
  }
  return isEmpty(correctResponse) ? undefined : correctResponse;
};

export {
  getPartLetter,
  scoreProblemDefinitionForStandardsReport,
  getProblemTypeItems,
  getProblemTypeDisplayName,
  getProblemTypeDescription,
  getAnswerTypeItems,
  getAnswerTypeDisplayName,
  getAnswerTypeDescription,
  getAnswerParts,
  getCorrectAnswer,
};
