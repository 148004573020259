var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-16",staticStyle:{"z-index":"3"}},[_vm._m(0),_c('v-data-table',{staticClass:"elevation-1 rounded-0",attrs:{"disable-pagination":"","headers":_setup.staticHeaders,"items":_setup.tableRows,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers, index }){return [_c('tr',{staticClass:"no-hover",class:item.class},_vm._l((headers),function(header){return _c('td',{key:`${index}-${header.value}`,class:header.cellClass},[(
              header.value == _setup.StaticHeaders.ACTION_TYPE &&
              item.actionType == _setup.ActionType.PROBLEM_STARTED_ACTION
            )?_c('a',{on:{"click":function($event){return _setup.emit('openProblemSideSheet', item.prLog.prCeri)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")]):(
              header.value == _setup.StaticHeaders.RESPONSE && item[header.value]
            )?_c('div',[(
                item.actionType ===
                  _setup.ActionType.CAIT_MESSAGE_REQUESTED_ACTION ||
                item.actionType === _setup.ActionType.CAIT_MESSAGE_RESPONSE_ACTION
              )?_c('div',[_c('div',{staticClass:"d-flex py-1",domProps:{"innerHTML":_vm._s(item[header.value])}})]):(_setup.problemMap[item.prLog.prCeri])?_c(_setup.AnswersViewSDK3,{staticClass:"py-1",attrs:{"answersSDK3":_setup.problemMap[item.prLog.prCeri].answersSDK3,"response":item[header.value],"showAnswerPool":false}}):_vm._e()],1):(
              header.value == _setup.StaticHeaders.TEACHER_FEEDBACK &&
              item.actionType == _setup.ActionType.STUDENT_SUBMISSION_ACTION
            )?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"neutral lighten-4"},on:{"click":function($event){return _setup.emit('openFeedbackSideSheet', item.prLog)}}},[(typeof item.score === 'number')?_c('span',[_vm._v("EDIT FEEDBACK")]):_c('span',[_vm._v("ASSESS")])]):_c('span',[_vm._v(" "+_vm._s(item[header.value]))])],1)}),0)]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-space-between align-center py-4 pl-6 neutral lighten-4 rounded-t elevation-1"},[_c('div',{staticClass:"text-h4",attrs:{"role":"heading"}},[_vm._v("Detailed Report")])])
}]

export { render, staticRenderFns }