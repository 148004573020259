var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"data-cy":"problem"}},[_c('div',{staticClass:"containChildren content-body",class:{ collapsed: _vm.collapsed },attrs:{"id":`${_vm.problem.xref}-question`},domProps:{"innerHTML":_vm._s(_setup.problemBody)}}),(!_vm.collapsed)?[(
        _vm.problem.answersSDK3 &&
        ![_setup.ProblemTypeSDK3.DRAG_DROP, _setup.ProblemTypeSDK3.FILL_IN].includes(
          _vm.problem.problemTypeSDK3
        )
      )?_c(_setup.AnswersViewSDK3,{attrs:{"answersSDK3":_vm.problem.answersSDK3,"response":_vm.response}}):_vm._e(),(_vm.problem.problemTypeSDK3 == _setup.ProblemTypeSDK3.DRAG_DROP)?_c('div',{staticClass:"dd-container rounded neutral lighten-3 my-2 pa-3"},_vm._l((_setup.answerPoolValues),function(apv,index){return _c('span',{key:index,staticClass:"dd-value content-body px-2",domProps:{"innerHTML":_vm._s(apv)}})}),0):_vm._e(),(_setup.attributions.length)?_c(_setup.Attribution,{staticClass:"mt-4",attrs:{"attributionXrefs":_setup.attributions}}):_vm._e()]:_vm._e(),_c('div',{key:_setup.renderKey,staticStyle:{"visibility":"hidden"}},_vm._l((_setup.fillInParts),function(fip){return _c('div',{key:fip.htmlMarker,ref:"fip",refInFor:true,attrs:{"marker":fip.htmlMarker}},[(fip.answerType == _setup.AnswerType.DRAG_DROP)?_c('div',{staticClass:"d-flex align-center"},[(_vm.showCorrectnessIcon && _setup.responseCorrectness[fip.htmlMarker])?_c('v-icon',{attrs:{"color":_setup.responseCorrectness[fip.htmlMarker].color}},[_vm._v(" "+_vm._s(_setup.responseCorrectness[fip.htmlMarker].icon)+" ")]):_vm._e(),_c('div',{staticClass:"dd-container"},_vm._l((_vm.response[fip.htmlMarker]),function(res,index){return _c('span',{key:index,staticClass:"dd-value content-body px-2",domProps:{"innerHTML":_vm._s(res)}})}),0)],1):(
          fip.answerType == _setup.AnswerType.DROP_DOWN && _vm.mode == _setup.ViewMode.PRINT
        )?_c(_setup.PrintableDropdown,{staticClass:"printable-dropdown mx-1",attrs:{"answers":fip.answerValues}}):(fip.answerType == _setup.AnswerType.DROP_DOWN)?_c('v-select',{attrs:{"items":fip.answerValues,"item-text":"value","item-value":"value","value":_vm.response[fip.htmlMarker],"background-color":"rgba(54, 109, 218, 0.08)","filled":"","menu-props":{ bottom: true, offsetY: true },"multiple":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.showCorrectnessIcon && _setup.responseCorrectness[fip.htmlMarker])?_c('v-icon',{attrs:{"color":_setup.responseCorrectness[fip.htmlMarker].color}},[_vm._v(" "+_vm._s(_setup.responseCorrectness[fip.htmlMarker].icon)+" ")]):_vm._e()]},proxy:true},{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":true}},'v-list-item',attrs,false),on),[_c('span',{staticClass:"content-body",domProps:{"innerHTML":_vm._s(item.value)}})])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"mx-2 content-body",domProps:{"innerHTML":_vm._s(item.value)}})]}}],null,true)}):(_setup.fillInAnswerTypes.includes(fip.answerType))?_c('v-text-field',{attrs:{"filled":"","dense":"","value":_vm.response[fip.htmlMarker],"readonly":"","hide-details":"","background-color":"rgba(54, 109, 218, 0.08)"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.showCorrectnessIcon && _setup.responseCorrectness[fip.htmlMarker])?_c('v-icon',{attrs:{"color":_setup.responseCorrectness[fip.htmlMarker].color}},[_vm._v(" "+_vm._s(_setup.responseCorrectness[fip.htmlMarker].icon)+" ")]):_vm._e()]},proxy:true}],null,true)}):_vm._e(),_c('div',{ref:"fipv",refInFor:true,staticClass:"d-inline-block",attrs:{"marker":fip.htmlMarker}},_vm._l((fip.answerValues),function(av){return _c('span',{key:av.value,staticClass:"d-block",domProps:{"innerHTML":_vm._s(av.value)}})}),0)],1)}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }