/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import { faker } from '@faker-js/faker';
import { flatten, uniq } from 'lodash';

faker.seed(0);

export default function (server: Server): void {
  // ALL unique primitive skills.
  const skills = uniq(
    flatten(server.schema.db.standardNodes.map((node) => node.skills))
  );
  for (let i = 1; i <= 30; i++) {
    server.create('studentAndPeerDataPerSkill', {
      skillXref: skills[i],
      averageScorePeers: faker.number.float({
        max: 1,
        precision: 0.01,
      }),
      numProblemsPeers: faker.number.int({
        min: 1,
        max: 300,
      }),
      studentData: [
        {
          studentXref: 'C9rUPIqxW7q2x9oLpSDG',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'wNmUOMlw2AIqZ8bKUF9',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'J5ihYVyU3yihj14QGdd5',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'qCEq425AKdzg4liLV7jV',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'hLVmvz6zgr6nmyEMW9qt',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
        {
          studentXref: 'v0GfOR8e7W2v1EhL2iU1',
          averageScore: faker.number.float({
            max: 1,
            precision: 0.01,
          }),
          numProblems: faker.number.int({
            min: 1,
            max: 50,
          }),
        },
      ],
    } as object);
  }
}
