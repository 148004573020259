var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[(_setup.reportReady)?[_c('div',{staticClass:"surface-container neutral lighten-4 elevation-1 px-12 py-4"},[_c('router-link',{staticClass:"text-overline text-decoration-none primary--text text--darken-2 font-weight-bold",attrs:{"to":{
          name: 'ReportLandingPage',
          params: { xref: _setup.assignment?.xref },
          query: _vm.$route.query,
        },"tag":"span","role":"button"}},[_c('span',{on:{"click":_setup.trackBackToAssignmentReport}},[_vm._v(" BACK TO ASSIGNMENT: "+_vm._s(_setup.assignment?.name))])]),_c('div',{staticClass:"text-h3 mb-0 pt-4"},[_vm._v("Scoring: "+_vm._s(_setup.title))]),_c('div',{staticClass:"pt-4"},[_c('v-row',[_c('v-col',{staticClass:"text-left pl-0"},[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_setup.index <= 0 || _setup.updating > 0},on:{"click":function($event){return _setup.updateIndex(_setup.Direction.PREVIOUS)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" PREVIOUS PROBLEM ")],1)],1),_c('v-col',{staticClass:"text-right pr-0"},[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_setup.index < 0 ||
                _setup.index == _setup.problemsAssigned.length - 1 ||
                _setup.updating > 0},on:{"click":function($event){return _setup.updateIndex(_setup.Direction.NEXT)}}},[_vm._v(" NEXT PROBLEM "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1),_c('div',{staticClass:"pa-6 my-8 mx-12 neutral lighten-4 elevation-1"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_setup.toggleShowQuestion}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_setup.showQuestion ? 'Hide' : 'Show')+" Problem ")],1),(_setup.showQuestion)?_c('div',{staticClass:"d-flex flex-column mt-4 px-4 content-body",domProps:{"innerHTML":_vm._s(_setup.problem?.question)}}):_vm._e()],1),(_setup.isQCLiveTeacher)?_c('div',{staticClass:"pa-4 my-0 mx-12 text-body-1 warning--text elevation-1"},[_c('span',[_vm._v(" Attention Teachers: We are currently testing a beta feature to bring you more accurate feedback suggestion. Please allow 1-3 minutes for this page to load. ")])]):_vm._e(),_c(_setup.EssayScoringTable,{attrs:{"assignees":_setup.assignees,"problemLogs":_setup.problemLogs,"quickComments":_setup.quickCommentsDownloaded,"quickCommentsStatus":_setup.quickCommentsStatus,"updating":_setup.updating},on:{"update-part-log":_setup.updateProblemPartLog,"open-response":_setup.openResponseDialog}}),_c(_setup.OpenResponseTextDialog,{attrs:{"response":_setup.response},model:{value:(_setup.showResponse),callback:function ($$v) {_setup.showResponse=$$v},expression:"showResponse"}})]:[_c('v-container',{staticClass:"d-flex justify-center align-center my-a",attrs:{"fill-height":""}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"100"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }