import { AssignmentDefinition } from '@/domain/Assignment';
import { ProblemDefinition } from '@/domain/Problem';
import { StudentData } from '@/domain/ReportData/StudentData';
import { ProblemRow, AssignmentTable } from '@/domain/ReportData/Tutor';

import { sortBy } from 'lodash';

export function transformToAssignmentTable(
  assignmentStudentData: StudentData | undefined,
  allProblems: {
    [prXref: string]: { problem: ProblemDefinition; index: number };
  },
  assignmentData: AssignmentDefinition
): AssignmentTable {
  const assignmentXref = assignmentData.xref;
  const assignmentName = assignmentData.name;
  const problemSetCeri = assignmentData.problemSetCeri;

  // Assignment-level info
  const assignmentTable: AssignmentTable = {
    problemSetCeri,
    assignmentXref,
    assignmentName,
    problems: [],
    assignmentStudentData,
  };

  const problems: { [xref: string]: ProblemRow } = {};

  // Aggregate problems in the assignment
  if (assignmentStudentData) {
    if (assignmentStudentData.prAllStats) {
      assignmentStudentData.prAllStats.forEach((data) => {
        const targetProblemRow = allProblems[data.prCeri];

        if (targetProblemRow) {
          problems[data.prCeri] = {
            problem: targetProblemRow.problem,
            index: targetProblemRow.index,
            problemData: data,
            studentData: {},
          };
        }
      });
    }

    // Aggregate student data for each problem
    assignmentStudentData.studentLogs.forEach((studentLog) => {
      if (studentLog.problemLogAndActions) {
        studentLog.problemLogAndActions.forEach((problemLogAndAction) => {
          if (problems[problemLogAndAction.prLog.prCeri]) {
            problems[problemLogAndAction.prLog.prCeri].studentData[
              studentLog.studentXref
            ] = problemLogAndAction;
          } // else: shouldn't happen right? Why would a problem not exists in assignment?
        });
      }
    });
  }
  // Flatten dictionary into array of ProblemRows, sorted by original index
  assignmentTable.problems = sortBy(Object.values(problems), 'index');

  return assignmentTable;
}
