import { MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { User } from '@/domain/User';
import { InsightsHubState } from './types';
import { TeacherCurriculumStats } from '@/domain/ReportData/InsightsHub';
import { CurriculumDefinition, CurriculumGrade } from '@/domain/Curriculum';
import { CourseDefinition } from '@/domain/Course';
import { School } from '@/domain/School';
import { DataOptions } from 'vuetify';
import { TimeSelector } from '@/domain/Time';
import { CancelTokenSource } from 'axios';
import { FolderDefinition } from '@/domain/Folder';
import { TotalStandardStats } from '@/domain/ReportData/InsightsHub';
import { StandardNodeDefinition, SkillStrand } from '@/domain/Skill';

export const mutations: MutationTree<InsightsHubState> = {
  setGradeFolders(state: InsightsHubState, folders: FolderDefinition[]) {
    state.gradeFolders = [...folders];
  },
  setMenteeTeachers(state: InsightsHubState, menteeTeachers: Array<User>) {
    state.menteeTeachers = cloneDeep(menteeTeachers);
  },
  setMenteeCourses(
    state: InsightsHubState,
    menteeCourses: Array<CourseDefinition>
  ) {
    state.menteeCourses = cloneDeep(menteeCourses);
  },
  setMenteeSchools(state: InsightsHubState, menteeSchools: Array<School>) {
    state.menteeSchools = cloneDeep(menteeSchools);
  },
  setSelectedCurriculum(
    state: InsightsHubState,
    curriculum: CurriculumDefinition
  ) {
    state.selectedCurriculum = curriculum;
  },
  setSelectedGradeXref(state: InsightsHubState, gradeXref: string) {
    state.selectedGradeXref = gradeXref;
  },
  setSelectedModuleId(state: InsightsHubState, moduleId: number) {
    state.selectedModuleId = moduleId;
  },
  setTeacherCurriculumStats(
    state: InsightsHubState,
    statsPerCurriculumGrade: Array<TeacherCurriculumStats>
  ) {
    state.teacherCurriculumStats = cloneDeep(statsPerCurriculumGrade);
  },
  setCurriculumGrade(
    state: InsightsHubState,
    curriculumGrade: CurriculumGrade | null
  ) {
    state.curriculumGrade = cloneDeep(curriculumGrade);
  },
  setSelectedMode(state: InsightsHubState, selectedMode: string) {
    state.selectedMode = selectedMode;
  },
  setSelectedPsTypes(state: InsightsHubState, selectedPsTypes: string[]) {
    state.selectedPsTypes = selectedPsTypes;
  },
  setSelectedAssessmentType(
    state: InsightsHubState,
    selectedAssessmentType: string | null
  ) {
    state.selectedAssessmentType = selectedAssessmentType;
  },
  setAnonymized(state: InsightsHubState, anonymized: boolean) {
    state.anonymized = anonymized;
  },
  setSelectedTab(state: InsightsHubState, selectedTab: number | null) {
    state.selectedTab = selectedTab;
  },
  setCollapsedPaths(state: InsightsHubState, collapsedPaths: Set<string>) {
    state.collapsedPaths = collapsedPaths;
  },
  setOptions(state: InsightsHubState, options: DataOptions) {
    state.options = options;
  },
  setTimeSelector(state: InsightsHubState, timeSelector: TimeSelector | null) {
    state.timeSelector = timeSelector;
  },
  setDashboardLoading(state: InsightsHubState, dashboardLoading: boolean) {
    state.dashboardLoading = dashboardLoading;
  },
  setDashboardSource(
    state: InsightsHubState,
    dashboardSource: CancelTokenSource | null
  ) {
    state.dashboardSource = dashboardSource;
  },
  setCurrentTimeData(
    state: InsightsHubState,
    currentTimeData: { data: TotalStandardStats; strandId: string }
  ) {
    state.currentTimeData.set(currentTimeData.strandId, currentTimeData.data);
  },
  resetCurrentTimeData(state: InsightsHubState) {
    state.currentTimeData.clear();
    state.selectedStrandId = null;
  },
  setStudentDisplayNamesMap(
    state: InsightsHubState,
    courseData: { courseXref: string; courseRoster: User[] }
  ) {
    const userMap = new Map<string, string>();

    courseData.courseRoster.forEach((user) => {
      userMap.set(user.xref, user.displayName);
    });

    state.studentDisplayNamesMap.set(courseData.courseXref, userMap);
  },
  setCurrentTimeCoherencyData(
    state: InsightsHubState,
    currentTimeData: { data: TotalStandardStats; node: string }
  ) {
    state.currentTimeCoherencyData.set(
      currentTimeData.node,
      currentTimeData.data
    );
  },
  setSkillStrands(state: InsightsHubState, skillStrands: SkillStrand[]) {
    state.skillStrands = skillStrands;
  },
  setSelectedStrand(state: InsightsHubState, selectedStrandId: number) {
    state.selectedStrandId = selectedStrandId;
  },
  setHasDownloaded(state: InsightsHubState, flag: boolean) {
    state.hasDownloadedSkills = flag;
  },
  setIsDownloading(state: InsightsHubState, flag: boolean) {
    state.isDownloadingSkills = flag;
  },
  setStandardNodes(state: InsightsHubState, newList: StandardNodeDefinition[]) {
    state.standardNodes = cloneDeep(newList);
  },
};
