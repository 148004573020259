import {
  CurriculumDefinition,
  CurriculumGrade,
  CurriculumHierarchyLevel,
  LessonDefinition,
  ModuleDefinition,
  ProblemSetTypeStats,
} from '@/domain/Curriculum';
import {
  ClassCurriculumStats,
  ClassStatsPerLesson,
  ClassStatsPerModule,
  StatsPerProblemSetType,
  TeacherCurriculumStats,
  TotalAssessmentStats,
} from '@/domain/ReportData/InsightsHub';
import { IsActiveFilterType } from '@/domain/State';
import { TimeSelector } from '@/domain/Time';
import { coreAxios } from '@/plugins/axios';
import { CancelToken } from 'axios';
import { DefinitionInclude } from '@/api/core/base.api';

const END_POINT = '/curricula';

const getCurriculumDefinitions = (): Promise<CurriculumDefinition[]> => {
  return coreAxios.get(`${END_POINT}`).then((res) => {
    return res.data;
  });
};

export interface CurriculumGradeDataParams {
  mentor?: string;
  primaryTeachers?: boolean;
  activeFilter?: IsActiveFilterType;
  timeSelector?: TimeSelector;
}

const getTeacherCurriculumStats = (
  curriculumXref: string,
  gradeFolderXref: string,
  params: CurriculumGradeDataParams,
  cancelToken?: CancelToken
): Promise<TeacherCurriculumStats[]> => {
  const { timeSelector, ...rest } = params;

  return coreAxios
    .get(
      `${END_POINT}/${curriculumXref}/grade-folders/${gradeFolderXref}/curriculum-grade-data`,
      {
        params: { timeSelector: timeSelector?.toArray(), ...rest },
        cancelToken,
      }
    )
    .then((res) => {
      return res.data.map(transformTeacherStats);
    });
};

const getAssessmentStats = (
  curriculumXref: string,
  gradeFolderXref: string,
  params: CurriculumGradeDataParams,
  cancelToken?: CancelToken
): Promise<TotalAssessmentStats> => {
  const { timeSelector, ...rest } = params;
  return coreAxios
    .get(
      `${END_POINT}/${curriculumXref}/grade-folders/${gradeFolderXref}/achievement-data`,
      {
        params: { timeSelector: timeSelector?.toArray(), ...rest },
        cancelToken,
      }
    )
    .then((res) => {
      return res.data;
    });
};

// Requests curricular structure of a grade folder
const getCurriculumGradeStructure = (
  curriculumXref: string,
  gradeFolderXref: string,
  cancelToken?: CancelToken
): Promise<CurriculumGrade> => {
  return coreAxios
    .get(
      `${END_POINT}/${curriculumXref}/grade-folders/${gradeFolderXref}/folder-structure`,
      { cancelToken }
    )
    .then((res) => {
      return transformCurriculumGrade(res.data);
    });
};

export interface CurriculumFoldersParam {
  levels?: CurriculumHierarchyLevel[];
  include?: DefinitionInclude[];
}

////////////////////////
// Insights Hub DTO's //
////////////////////////
// Curriculum Structure
export interface CurriculumGradeDTO {
  // Name for modules in this curriculum e.g. 'Unit'
  moduleTitle: string;
  lessonTitle: string;
  folderId: number;
  modules: Array<ModuleDefinitionDTO>;
}

export interface ModuleDefinitionDTO {
  moduleName: string;
  moduleNumber: string;
  folderId: number;
  lessons: Array<LessonDefinitionDTO>;
  assessmentTypeStats: Array<ProblemSetTypeStats>;
}

export interface LessonDefinitionDTO {
  lessonName: string;
  lessonNumber: string;
  folderId: number;
  problemSetTypeStats: Array<ProblemSetTypeStats>;
}

// Interaction Data
export interface TeacherCurriculumStatsDTO {
  teacherXref: string;
  classStats: Array<ClassCurriculumStatsDTO>;
}

export interface ClassCurriculumStatsDTO {
  classXref: string;
  moduleStats: Array<ClassStatsPerModuleDTO>;
}

export interface ClassStatsPerModuleDTO {
  folderId: number;
  lessonStats: Array<ClassStatsPerLessonDTO>;
  assessmentTypeStats: Array<StatsPerProblemSetType>;
}

export interface ClassStatsPerLessonDTO {
  folderId: number;
  problemSetTypeStats: Array<StatsPerProblemSetType>;
}

/////////////////////////
// DTO Transformations //
/////////////////////////
function transformCurriculumGrade(
  curriculumGrade: CurriculumGradeDTO
): CurriculumGrade {
  const res: CurriculumGrade = {
    folderId: curriculumGrade.folderId,
    moduleTitle: curriculumGrade.moduleTitle,
    lessonTitle: curriculumGrade.lessonTitle,
    modules: new Map<number, ModuleDefinition>(),
    numProblemsPerProblemSetType: new Map(),
    numProblemsPerAssessmentType: new Map(),
  };
  for (const module of curriculumGrade.modules) {
    const transformedModule = transformModuleDefinition(module);
    res.modules.set(module.folderId, transformedModule);
    for (const [
      psType,
      count,
    ] of transformedModule.numProblemsPerProblemSetType) {
      let countOfThisType = res.numProblemsPerProblemSetType.get(psType) || 0;
      countOfThisType += count;
      res.numProblemsPerProblemSetType.set(psType, countOfThisType);
    }
    for (const [aType, aTypeStats] of transformedModule.assessmentTypeStats) {
      let countOfThisType = res.numProblemsPerAssessmentType.get(aType) || 0;
      countOfThisType += aTypeStats.numProblems;
      res.numProblemsPerAssessmentType.set(aType, countOfThisType);
    }
  }
  return res;
}
function transformModuleDefinition(
  module: ModuleDefinitionDTO
): ModuleDefinition {
  const res: ModuleDefinition = {
    folderId: module.folderId,
    moduleName: module.moduleName,
    moduleNumber: module.moduleNumber,
    lessons: new Map<number, LessonDefinition>(),
    assessmentTypeStats: new Map<string, ProblemSetTypeStats>(),
    numProblemsPerProblemSetType: new Map<string, number>(),
  };
  if (module.lessons) {
    for (const lesson of module.lessons) {
      res.lessons.set(lesson.folderId, transformLessonDefinition(lesson));
      // Count problems from each set type
      for (const problemSetType of lesson.problemSetTypeStats) {
        // Current ps type
        let countOfThisType =
          res.numProblemsPerProblemSetType.get(problemSetType.type) || 0;
        countOfThisType += problemSetType.numProblems;
        res.numProblemsPerProblemSetType.set(
          problemSetType.type,
          countOfThisType
        );
      }
    }
  }
  if (module.assessmentTypeStats) {
    for (const assessment of module.assessmentTypeStats) {
      res.assessmentTypeStats.set(assessment.type, assessment);
    }
  }
  return res;
}
function transformLessonDefinition(
  lesson: LessonDefinitionDTO
): LessonDefinition {
  const res: LessonDefinition = {
    folderId: lesson.folderId,
    lessonName: lesson.lessonName,
    lessonNumber: lesson.lessonNumber,
    problemSetTypeStats: new Map<string, ProblemSetTypeStats>(),
  };
  for (const problemSetType of lesson.problemSetTypeStats) {
    res.problemSetTypeStats.set(problemSetType.type, problemSetType);
  }
  return res;
}

function transformTeacherStats(
  teacherStats: TeacherCurriculumStatsDTO
): TeacherCurriculumStats {
  const res: TeacherCurriculumStats = {
    teacherXref: teacherStats.teacherXref,
    classStats: [],
    summaryProblemSetTypeStats: new Map<string, StatsPerProblemSetType>(),
    summaryAssessmentTypeStats: new Map<string, StatsPerProblemSetType>(),
  };
  for (const classStatsToTransform of teacherStats.classStats) {
    const transformedClass: ClassCurriculumStats = {
      classXref: classStatsToTransform.classXref,
      moduleStats: new Map<number, ClassStatsPerModule>(),
      summaryProblemSetTypeStats: new Map<string, StatsPerProblemSetType>(),
      summaryAssessmentTypeStats: new Map<string, StatsPerProblemSetType>(),
    };
    for (const classModule of classStatsToTransform.moduleStats) {
      const transformedClassModule = transformClassModule(classModule);
      transformedClass.moduleStats.set(
        classModule.folderId,
        transformedClassModule
      );
      for (const [
        psType,
        psTypeStats,
      ] of transformedClassModule.summaryProblemSetTypeStats) {
        // Update course-level aggregate data based on this module
        const courseAccumulated =
          transformedClass.summaryProblemSetTypeStats.get(psType) ??
          ({} as StatsPerProblemSetType);
        transformedClass.summaryProblemSetTypeStats.set(
          psType,
          mergeProblemSetTypeStats(courseAccumulated, psTypeStats)
        );
        // Update teacher-level aggregate data based on this module
        const teacherAccumulated =
          res.summaryProblemSetTypeStats.get(psType) ??
          ({} as StatsPerProblemSetType);
        res.summaryProblemSetTypeStats.set(
          psType,
          mergeProblemSetTypeStats(teacherAccumulated, psTypeStats)
        );
      }
      for (const [
        aType,
        aTypeStats,
      ] of transformedClassModule.assessmentTypeStats) {
        const courseAccumulated =
          transformedClass.summaryAssessmentTypeStats.get(aType) ??
          ({} as StatsPerProblemSetType);
        transformedClass.summaryAssessmentTypeStats.set(
          aType,
          mergeProblemSetTypeStats(courseAccumulated, aTypeStats)
        );

        const teacherAccumulated =
          res.summaryAssessmentTypeStats.get(aType) ??
          ({} as StatsPerProblemSetType);
        res.summaryAssessmentTypeStats.set(
          aType,
          mergeProblemSetTypeStats(teacherAccumulated, aTypeStats)
        );
      }
    }
    res.classStats.push(transformedClass);
  }
  return res;
}

function transformClassModule(
  classModule: ClassStatsPerModuleDTO
): ClassStatsPerModule {
  const res: ClassStatsPerModule = {
    folderId: classModule.folderId,
    lessonStats: new Map<number, ClassStatsPerLesson>(),
    assessmentTypeStats: new Map<string, StatsPerProblemSetType>(),
    summaryProblemSetTypeStats: new Map<string, StatsPerProblemSetType>(),
  };
  // Set lesson stats
  for (const classLesson of classModule.lessonStats) {
    const transformedClassLesson = transformClassLesson(classLesson);
    for (const [
      psType,
      psTypeStats,
    ] of transformedClassLesson.problemSetTypeStats) {
      const accumulated =
        res.summaryProblemSetTypeStats.get(psType) ??
        ({} as StatsPerProblemSetType);
      res.summaryProblemSetTypeStats.set(
        psType,
        mergeProblemSetTypeStats(accumulated, psTypeStats)
      );
    }
    res.lessonStats.set(classLesson.folderId, transformedClassLesson);
  }
  // Set assessment stats
  for (const classAssessments of classModule.assessmentTypeStats) {
    res.assessmentTypeStats.set(
      classAssessments.problemSetType,
      classAssessments
    );
  }
  return res;
}

function transformClassLesson(
  classLesson: ClassStatsPerLessonDTO
): ClassStatsPerLesson {
  const res: ClassStatsPerLesson = {
    folderId: classLesson.folderId,
    problemSetTypeStats: new Map<string, StatsPerProblemSetType>(),
  };
  for (const psStats of classLesson.problemSetTypeStats) {
    res.problemSetTypeStats.set(psStats.problemSetType, psStats);
  }
  return res;
}

// FIXME: Util function specific to Insights Hub?
// Expected to be of the same PS Type; otherwise only PS Type of a is accounted for
export function mergeProblemSetTypeStats(
  a: StatsPerProblemSetType,
  b: StatsPerProblemSetType
): StatsPerProblemSetType {
  const res = { ...a };

  if (b.assignmentXrefs) {
    const assignmentXrefs = res.assignmentXrefs ?? [];
    assignmentXrefs.push(...b.assignmentXrefs);
    res.assignmentXrefs = Array.from(new Set(assignmentXrefs));
  }

  if (b.totalNumUniqueProblemsAssigned != null) {
    res.totalNumUniqueProblemsAssigned =
      (res.totalNumUniqueProblemsAssigned ?? 0) +
      b.totalNumUniqueProblemsAssigned;
  }

  if (b.totalNumProblemsAssigned != null) {
    res.totalNumProblemsAssigned =
      (res.totalNumProblemsAssigned ?? 0) + b.totalNumProblemsAssigned;
  }

  if (b.totalNumProblemsCompleted != null) {
    res.totalNumProblemsCompleted =
      (res.totalNumProblemsCompleted ?? 0) + b.totalNumProblemsCompleted;
  }

  if (b.sumOfStudentAverageScores != null) {
    res.sumOfStudentAverageScores =
      (res.sumOfStudentAverageScores ?? 0) + b.sumOfStudentAverageScores;
  }

  if (b.numStudentsScored != null) {
    res.numStudentsScored = (res.numStudentsScored ?? 0) + b.numStudentsScored;
  }

  return res;
}

export {
  getCurriculumDefinitions,
  getTeacherCurriculumStats,
  getCurriculumGradeStructure,
  getAssessmentStats,
};
