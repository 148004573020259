import { StandardNodeDefinition } from '@/domain/Skill';
import { useStore } from '@/store';
import { uniqBy } from 'lodash';

const store = useStore();

export function getStandardNodesFromSkills(
  skills?: string[]
): StandardNodeDefinition[] {
  const skillNodesMap = store.getters['skillList/getSkillNodesMap'];
  const standardNodesMap = store.getters['skillList/getStandardNodesMap'];
  const xrefs = skills ?? [];
  const standardNodes: StandardNodeDefinition[] = [];
  for (const skill of xrefs) {
    // Mapped standard node.
    const skillNode = skillNodesMap[skill];
    const standardNode = standardNodesMap[skillNode];
    if (standardNode) {
      standardNodes.push(standardNode);
    }
  }
  // Avoid duplicates. May be tagged with multiple skills of the standard node.
  return uniqBy(standardNodes, (node) => node.xref);
}
