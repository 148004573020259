import { ProblemLogAndActions } from '@/domain/ReportData/StudentData';
import { User } from '@/domain/User';

export const sortBySortableUserName = (a: User, b: User): number => {
  const sortableNameA = `${a.lastName},${a.firstName}`;
  const sortableNameB = `${b.lastName},${b.firstName}`;
  return sortableNameA.localeCompare(sortableNameB);
};

export const sortBySortableString = (a: string, b: string): number =>
  a.localeCompare(b);

export const sortBySortableNumber = (a: number, b: number): number =>
  a > b ? 1 : -1;

export const sortPercentage = (a: string, b: string): number => {
  if (a == '-') {
    return -1;
  }
  if (b == '-') {
    return 1;
  }
  return parseFloat(a) > parseFloat(b) ? 1 : -1;
};

// Sorting order (least to greatest):
// 1) unfinished problem
// 2) red redo
// 3) red 0
// 4) orange redo
// 5) orange 0
// 6) orange 1-99
// 7) green redo
// 8) green
export const sortScore = (
  a?: ProblemLogAndActions,
  b?: ProblemLogAndActions
): number => {
  const aLog = a?.prLog;
  const bLog = b?.prLog;
  // checking for unfinished problems
  if (!bLog?.endTime) {
    return 1;
  }
  if (!aLog?.endTime) {
    return -1;
  }
  // checking for red redo
  if (bLog.sawAnswer) {
    return 1;
  }
  if (aLog.sawAnswer) {
    return -1;
  }
  // comparing orange redo and green redo
  if (bLog.redoAverageScore === 1 && aLog.redoAverageScore) {
    return -1;
  }
  if (aLog.redoAverageScore === 1 && bLog.redoAverageScore) {
    return 1;
  }
  return aLog.continuousScore - bLog.continuousScore;
};
