import { ActionTree } from 'vuex';
import { SkillState } from './types';
import { RootState } from '../types';
import { getStandardNodes, Standard } from '@/api/core/skills.api';
import { StandardNodeDefinition } from '@/domain/Skill';

export const actions: ActionTree<SkillState, RootState> = {
  /**
   * Download all the skills
   * if not already downloaded/downloading
   */
  requestStandardNodes(
    context,
    standard?: Standard
  ): Promise<StandardNodeDefinition[]> {
    if (!context.state.hasDownloaded && !context.state.isDownloading) {
      // Let the component decide when to load or reload skills
      // Update loading state here to notify other instances or components if
      // we do perform such operation rather than having the calling component do
      // this kind of update?
      context.commit('setIsDownloading', true);
      return getStandardNodes(standard).then(
        (nodes: StandardNodeDefinition[]) => {
          context.commit('setHasDownloaded', true);
          context.commit('setIsDownloading', false);
          context.commit('setStandardNodes', nodes);
          return nodes;
        }
      );
    }
    return Promise.resolve(context.state.standardNodes);
  },
};
