import {
  StudentData,
  StudentDataOverTime,
} from '@/domain/ReportData/StudentData';
import { coreAxios } from '@/plugins/axios';
import {
  StandardOverTimeDateData,
  TotalStandardStats,
} from '@/domain/ReportData/InsightsHub';
import { TimeSelector } from '@/domain/Time';
import { CancelToken } from 'axios';
import { StudentAndPeerDataPerSkill } from '@/domain/ReportData/Tutor';
import { Standard } from './skills.api';
import { ProblemSetType } from '@/domain/ProblemSet';

const END_POINT = '/sdata';

export enum StudentDataReturnType {
  ASSIGNMENT_LOGS = 'ASSIGNMENT_LOGS',
  PROBLEM_LOGS = 'PROBLEM_LOGS',
  ACTION_LOGS = 'ACTION_LOGS',
  SUMMARY_STATISTICS = 'SUMMARY_STATISTICS',
  PROBLEM_STATISTICS = 'PROBLEM_STATISTICS',
}

export enum ActionGroupsType {
  ASSIGNMENT_ACTIONS = 'ASSIGNMENT_ACTIONS',
  PROBLEM_SET_ACTIONS = 'PROBLEM_SET_ACTIONS',
  PROBLEM_ACTIONS = 'PROBLEM_ACTIONS',
  RESPONSE_ACTIONS = 'RESPONSE_ACTIONS',
  TUTORING_REQUEST_ACTIONS = 'TUTORING_REQUEST_ACTIONS',
  TIMER_ACTIONS = 'TIMER_ACTIONS',
}

export enum StatisticsForType {
  ALL_STUDENTS = 'ALL_STUDENTS',
  SPECIFIED_STUDENTS = 'SPECIFIED_STUDENTS',
}

export interface StudentDataParams {
  include: Array<StudentDataReturnType>;
  actions?: Array<ActionGroupsType>;
  statisticsFor?: Array<StatisticsForType>;
  groupsSubset?: Array<string>;
  usersSubset?: Array<string>;
  groupsScope?: Array<string>;
  assignmentsScope?: Array<string>;
}

interface StandardOverTimeParams {
  xrefs: Array<string>;
  gradeLevelXref: string;
  overTimeGraphType: string;
  timeSelector: TimeSelector;
}

interface StandardCurrentTimeParams {
  primaryTeachers: boolean;
  mentor: string;
  gradeLevel: string;
  timeSelector: TimeSelector;
}

interface StandardCoherenceParams {
  primaryTeachers: boolean;
  mentor: string;
  gradeLevel: string;
  timeSelector: TimeSelector;
  skills: Array<string>;
}

const getOverTimeDataForAssignments = (
  courses: string[],
  releaseDate: string,
  problemSetTypes: ProblemSetType[],
  controller?: AbortController
): Promise<StudentDataOverTime> => {
  return coreAxios
    .get(`${END_POINT}/over-time`, {
      params: { courses, releaseDate, problemSetTypes },
      signal: controller?.signal,
    })
    .then((res) => {
      return res.data;
    });
};

const getStandardOverTime = (
  standardHierarchyNodeId: number,
  params: StandardOverTimeParams
): Promise<StandardOverTimeDateData[]> => {
  return coreAxios
    .get(`${END_POINT}/standard-overtime-data/${standardHierarchyNodeId}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

const getStandardCurrentTime = (
  skillStrandId: number,
  params: StandardCurrentTimeParams,
  cancelToken?: CancelToken
): Promise<TotalStandardStats> => {
  const { timeSelector, ...rest } = params;
  return coreAxios
    .get(`${END_POINT}/standard-current-data/${skillStrandId}`, {
      params: { timeSelector: timeSelector?.toArray(), ...rest },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
};

const getStandardCoherencyData = (
  standard: Standard,
  params: StandardCoherenceParams,
  cancelToken?: CancelToken
): Promise<TotalStandardStats> => {
  const { timeSelector, ...rest } = params;
  return coreAxios
    .get(`${END_POINT}/standard-coherency-data/${standard}`, {
      params: { timeSelector: timeSelector?.toArray(), ...rest },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
};

// {
//   include: Array<StudentDataReturnType>;
//   statisticsFor?: Array<StatisticsForType>;
//   groupsSubset?: Array<string>;
//   usersSubset?: Array<string>;
//   groupsScope?: Array<string>;
//   assignmentsScope?: Array<string>;
// }
const getStudentDataForProblemSet = (
  psCeri: string,
  params: StudentDataParams
): Promise<StudentData> => {
  return coreAxios
    .get(`${END_POINT}/content/${psCeri}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

const getStudentAndPeerDataPerSkill = (
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<StudentAndPeerDataPerSkill[]> => {
  return coreAxios
    .get(`${END_POINT}/skillData`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export {
  getStudentAndPeerDataPerSkill,
  getStudentDataForProblemSet,
  getStandardOverTime,
  getStandardCurrentTime,
  getStandardCoherencyData,
  getOverTimeDataForAssignments,
};
