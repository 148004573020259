import { GetterTree } from 'vuex';
import { User } from '@/domain/User';
import { TutorState } from './types';
import { RootState } from '../types';
import { TimeFrame } from '@/domain/Time';
import { AssignmentDefinition } from '@/domain/Assignment';
import dayjs from 'dayjs';
import { StandardNodeDefinition } from '@/domain/Skill';

export const getters: GetterTree<TutorState, RootState> = {
  getSelectedTutees(state: TutorState): Array<User> {
    const selectedTutees: Array<User> = state.tutees.filter((tutee: User) =>
      state.selectedTuteeXrefs.includes(tutee.xref)
    );
    // Maintain order of selected tutees
    return selectedTutees.sort((a: User, b: User) => {
      return (
        state.selectedTuteeXrefs.indexOf(a.xref) -
        state.selectedTuteeXrefs.indexOf(b.xref)
      );
    });
  },
  getSelectedTimeFrame(state: TutorState): TimeFrame {
    const defaultTimeFrame = {
      startDateTime: dayjs().subtract(7, 'days').valueOf(),
      endDateTime: dayjs().valueOf(),
    };
    return state.selectedTimeFrame || defaultTimeFrame;
  },
  getSelectedAssignments(state: TutorState): Array<AssignmentDefinition> {
    return state.selectedAssignments || [];
  },
  getSelectedStandardNodes(state: TutorState): StandardNodeDefinition[] {
    return state.selectedStandardNodes || [];
  },
};
