import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store/index';
import { AuthenticatedUser, UserRole, UserSettings } from '@/domain/User';
import { RETURN_URL } from '@/domain/PageParams';
import { PageView } from './navigation.util';

///////////////////
// Router Guards //
///////////////////

function getRedirectUrlIfUnauthenticated(to: Route): string | null {
  if (!store.getters['auth/isAuthenticated']) {
    // Rebuild target destination
    // window.location.href doesn't work here because if the user
    // is coming from other parts of the application (not by URL)
    // the window.location.href will not be updated to target destination
    // before router guard kicks in
    const targetDestination = `${window.location.origin}${to.fullPath}`;
    const goTo401 = `/401?${RETURN_URL}=${targetDestination}`;
    return goTo401;
  }

  return null;
}

function getRedirectUrlIfUnauthorized(
  to: Route,
  allowedRoles: UserRole[],
  setting?: keyof UserSettings
): string | null {
  const goTo401 = getRedirectUrlIfUnauthenticated(to);

  if (goTo401 !== null) {
    // Needs to login
    return goTo401;
  }

  const currentUser: AuthenticatedUser = store.getters['auth/getCurrentUser'];

  const userRoles = currentUser?.roles ?? [];
  const userSettings = currentUser?.settings ?? ({} as UserSettings);

  // User has appropriate Role to access Page
  const hasRole =
    userRoles.length > 0 &&
    userRoles.some(
      (role) => role == UserRole.SERVICE_ADMIN || allowedRoles.includes(role)
    );

  // 08/11/2022 FIXME Setting value could be of type string or number?
  let hasSettingOn: boolean | string | number = true;

  if (setting) {
    // User has the specified setting turned on
    hasSettingOn = userSettings[setting] ?? false;
  }

  if (!hasRole || !hasSettingOn) {
    const targetDestination = `${window.location.origin}${to.fullPath}`;
    return `/403?${RETURN_URL}=${targetDestination}`;
  }

  return null;
}

/**
 * Check authentication status in store
 * @param {Route} to newly requested route
 * @param {Route} from previous route
 * @param {Function} next allows navigation or redirects
 */
export function enterIfAuthenticated(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): Promise<void> {
  return store.dispatch('initialize').then(() => {
    const goTo401 = getRedirectUrlIfUnauthenticated(to);

    if (goTo401 !== null) {
      // Needs to login
      return next(goTo401);
    }

    return next();
  });
}

/**
 * Check authentication status and roles of current user in store
 * @param {Route} to newly requested route
 * @param {Route} from previous route
 * @param {Function} next allows navigation or redirects
 */
export function enterIfTeacher(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): Promise<void> {
  return store.dispatch('initialize').then(() => {
    const goTo403 = getRedirectUrlIfUnauthorized(to, [UserRole.TEACHER]);

    if (goTo403 !== null) {
      // Not allowed to access this Page without TEACHER role
      return next(goTo403);
    }

    return next();
  });
}

/**
 * Check authentication status and roles of current user in store
 * @param {Route} to newly requested route
 * @param {Route} from previous route
 * @param {Function} next allows navigation or redirects
 * @param {UserRole[]} roles allows only if user has one of the roles
 * @param {keyof Settings} setting allows only if user has the specified setting turned on
 */
export function enterIfHasOneOfRoles(
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  roles: UserRole[],
  setting?: keyof UserSettings
): Promise<void> {
  return store.dispatch('initialize').then(() => {
    const goTo403 = getRedirectUrlIfUnauthorized(to, roles, setting);

    if (goTo403 !== null) {
      // Not allowed to access this Page without TEACHER or MENTOR role
      return next(goTo403);
    }

    return next();
  });
}

// FIXME: We need to change how/when we do store clean up.
export function afterEachReset(to: Route, from: Route): Promise<void> {
  // FIXME: Use PageView enum?
  // Ignore URL part updates by page.
  if (
    to.name != from.name &&
    to.meta?.page != from.meta?.page &&
    // FIXME: A hack to prevent clearing out previous search results.
    from.meta?.page != PageView.SEARCH_RESULTS &&
    to.meta?.page != PageView.SEARCH_RESULTS
  ) {
    return store.dispatch('reset');
  }
  return Promise.resolve();
}
