var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_setup.answerParts),function(member,memberIndex){return _c('div',{key:`${member.memberType}_${memberIndex}`},[(member.answerType === _setup.AnswerType.CHOOSE_N)?_c('div',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[(member.answerValues)?_vm._l((member.answerValues),function(answerValue,valueIndex){return _c('div',{key:valueIndex},[_c('v-row',{key:`${answerValue.value}_${valueIndex}`,staticClass:"mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{key:`${answerValue.value}_${valueIndex}`,staticClass:"my-3",attrs:{"data-cy":`checkbox-${valueIndex}`,"input-value":_vm.tutorStrategy.answerPartsToResponses[
                          member.htmlMarker
                        ].includes(answerValue.value),"disabled":_vm.disabled,"hide-details":""},on:{"click":function($event){return _setup.updateChooseN(member.htmlMarker, answerValue.value)}}})],1),_c('v-col',[_c('div',{staticClass:"d-flex align-stretch rounded bordered-answer"},[_c('div',{staticClass:"d-flex px-3 py-4 rounded neutral lighten-2 neutral--text text--darken-3 align-center"},[_vm._v(" "+_vm._s(_setup.getLabel( member.properties?.LABEL_START || '', valueIndex ))+" ")]),_c('span',{staticClass:"text-body-1 pl-4 pt-4 content-body d-flex align-center",domProps:{"innerHTML":_vm._s(answerValue.value)}})])])],1)],1)}):_vm._e()],2)],1)],1):_vm._e(),(member.answerType === _setup.AnswerType.SORT)?_c('div',[[_c(_setup.draggable,{attrs:{"draggable":".item","animation":"200","disabled":_vm.disabled},on:{"end":_setup.updateSort},model:{value:(
              _setup.localTutorStrategy.answerPartsToResponses[member.htmlMarker]
            ),callback:function ($$v) {_vm.$set(_setup.localTutorStrategy.answerPartsToResponses, member.htmlMarker, $$v)},expression:"\n              localTutorStrategy.answerPartsToResponses[member.htmlMarker]\n            "}},[_c('transition-group',{class:_vm.answerSet.properties?.STACKED || !_vm.answerSet.properties
                  ? ''
                  : 'd-flex align-center justify-center',attrs:{"enter-class":"drag-transition-enter"}},_vm._l((_vm.tutorStrategy
                  .answerPartsToResponses[member.htmlMarker]),function(answer,index){return _c('div',{key:`${answer}_${index}`,staticClass:"item"},[(
                    _vm.answerSet.properties?.STACKED || !_vm.answerSet.properties
                  )?_c('v-row',{staticClass:"align-stretch rounded bordered-answer ma-2",attrs:{"align":"center","data-cy":`sort-vertical-${index}`}},[_c('v-col',{staticClass:"label d-flex align-center",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"draggable"},[_vm._v(" mdi-drag ")])],1),_c('v-col',{staticClass:"text-body-1 content-body d-flex align-center pa-0 pl-3"},[_c('span',{staticClass:"html-content",domProps:{"innerHTML":_vm._s(answer)}})])],1):_c('div',{staticClass:"d-flex align-center justify-center mx-2 px-4 py-2 draggable horizontal-drag-target rounded",attrs:{"data-cy":`sort-horizontal-${index}`}},[_c('span',{staticClass:"d-flex content-body",domProps:{"innerHTML":_vm._s(answer)}})])],1)}),0)],1)]],2):_vm._e(),_c('v-row',{staticClass:"align-center my-3"},[(
            [
              _setup.AnswerType.EXACT_MATCH,
              _setup.AnswerType.ALGEBRA,
              _setup.AnswerType.EXACT_FRACTION,
              _setup.AnswerType.NUMERIC,
              _setup.AnswerType.NUMERIC_EXPRESSION,
            ].includes(member.answerType)
          )?[_c('span',{staticClass:"text-subtitle-1 mx-2",staticStyle:{"width":"300px"}},[_vm._v(_vm._s(`Incorrect Response for Answer ${member.htmlMarker}:`))]),_c('v-text-field',{staticClass:"fill-in-field",attrs:{"value":_vm.tutorStrategy.answerPartsToResponses[member.htmlMarker] &&
              _vm.tutorStrategy.answerPartsToResponses[member.htmlMarker][0]
                ? _vm.tutorStrategy.answerPartsToResponses[member.htmlMarker][0]
                : '',"background-color":"rgba(54, 109, 218, 0.08)","flat":"","solo":"","filled":"","clearable":"","hide-details":""},on:{"change":function($event){return _setup.updateFillIn(member.htmlMarker, $event)}}})]:_vm._e(),(member.answerType == _setup.AnswerType.DROP_DOWN)?[_c('span',{staticClass:"text-subtitle-1 mx-2",staticStyle:{"width":"300px"}},[_vm._v(_vm._s(`Incorrect Response for Dropdown ${member.htmlMarker}:`))]),_c('v-select',{staticClass:"fill-in-field",attrs:{"items":member.answerValues,"value":_vm.tutorStrategy.answerPartsToResponses[member.htmlMarker] &&
              _vm.tutorStrategy.answerPartsToResponses[member.htmlMarker][0]
                ? _vm.tutorStrategy.answerPartsToResponses[member.htmlMarker][0]
                : '',"background-color":"rgba(54, 109, 218, 0.08)","flat":"","solo":"","filled":"","clearable":"","menu-props":{ bottom: true, offsetY: true },"hide-details":""},on:{"change":function($event){return _setup.updateFillIn(member.htmlMarker, $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{staticClass:"content-body",domProps:{"innerHTML":_vm._s(item.value)}})],1)]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"mx-2 content-body",domProps:{"innerHTML":_vm._s(item.value)}})]}}],null,true)})]:_vm._e()],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }