import { GetterTree } from 'vuex';
import { StandardNodeDefinition } from '@/domain/Skill';
import { SkillState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<SkillState, RootState> = {
  getHasDownloaded(state: SkillState): boolean {
    return state.hasDownloaded;
  },
  getIsDownloading(state: SkillState): boolean {
    return state.isDownloading;
  },
  getSelectedStandardNode(state: SkillState): StandardNodeDefinition | null {
    const standardNode = state.standardNodes.find(
      (node) => node.xref === state.selectedStandardNode
    );
    return standardNode ?? null;
  },
  getStandardNodesMap(
    state: SkillState
  ): Record<string, StandardNodeDefinition> {
    const res: Record<string, StandardNodeDefinition> = {};
    state.standardNodes.forEach((node) => (res[node.xref] = node));
    return res;
  },
  // FIXME: Mapped to multiple standard nodes?
  getSkillNodesMap(state: SkillState): Record<string, string> {
    const res: Record<string, string> = {};
    for (const node of state.standardNodes) {
      const skills = node.skills;
      skills?.forEach((skill) => (res[skill] = node.xref));
    }
    return res;
  },
};
