import { render, staticRenderFns } from "./AssignmentDataTable.vue?vue&type=template&id=096834dc&scoped=true&"
import script from "./AssignmentDataTable.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AssignmentDataTable.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096834dc",
  null
  
)

export default component.exports